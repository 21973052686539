import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { supabase } from "../supabaseClient";
import placeholderImage from "../assets/Image-not-found.png";
import headerImage from "../assets/cheo1.jpg";

function Home() {
	const [artworks, setArtworks] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const artworksPerPage = 12;
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		fetchArtworks();

		// Get the page from the URL when component mounts
		const params = new URLSearchParams(location.search);
		const page = parseInt(params.get("page")) || 1;
		setCurrentPage(page);
	}, [location]);

	const handleImageError = (e) => {
		e.target.src = placeholderImage;
	};

	async function fetchArtworks() {
		const { data, error } = await supabase.from("artworks").select("*");
		if (error) console.error("Error fetching artworks:", error);
		else setArtworks(data);
	}

	const indexOfLastArtwork = currentPage * artworksPerPage;
	const indexOfFirstArtwork = indexOfLastArtwork - artworksPerPage;
	const currentArtworks = artworks.slice(
		indexOfFirstArtwork,
		indexOfLastArtwork
	);

	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
		navigate(`?page=${pageNumber}`);
	};

	const totalPages = Math.ceil(artworks.length / artworksPerPage);

	const getPageNumbers = () => {
		const pageNumbers = [];
		if (totalPages <= 5) {
			for (let i = 1; i <= totalPages; i++) {
				pageNumbers.push(i);
			}
		} else {
			pageNumbers.push(1);
			if (currentPage > 3) {
				pageNumbers.push("...");
			}
			if (currentPage === totalPages) {
				pageNumbers.push(currentPage - 2);
			}
			if (currentPage > 2) {
				pageNumbers.push(currentPage - 1);
			}
			if (currentPage !== 1 && currentPage !== totalPages) {
				pageNumbers.push(currentPage);
			}
			if (currentPage < totalPages - 1) {
				pageNumbers.push(currentPage + 1);
			}
			if (currentPage === 1) {
				pageNumbers.push(currentPage + 2);
			}
			if (currentPage < totalPages - 2) {
				pageNumbers.push("...");
			}
			if (currentPage !== totalPages) {
				pageNumbers.push(totalPages);
			}
		}
		return pageNumbers;
	};

	return (
		<div className="max-w-7xl mx-auto px-8 py-12">
			<div className="flex flex-col md:flex-row items-center justify-between mb-12">
				<img
					src={headerImage}
					alt="Header"
					className="w-full md:w-1/3 h-64 object-cover rounded-lg shadow-lg mb-8 md:mb-0"
				/>
				<h1 className="text-5xl text-center md:text-left mb-8 md:mb-0 text-gray-800 uppercase tracking-widest relative md:ml-12 flex-grow">
					Cheo Perez
					<span className="block w-24 h-1 bg-red-500 mt-4 mx-auto md:mx-0"></span>
				</h1>
			</div>
			<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mb-8">
				{currentArtworks.map((artwork) => (
					<Link
						key={artwork.id}
						to={`/artwork/${artwork.id}?page=${currentPage}`}
						className="no-underline text-inherit bg-white rounded-lg overflow-hidden shadow-md transition-transform duration-300 hover:translate-y-[-5px]"
					>
						<img
							src={
								supabase.storage
									.from("artwork-images")
									.getPublicUrl(artwork.picture_url).data.publicUrl
							}
							alt={artwork.title}
							onError={handleImageError}
							className="w-full h-48 object-cover"
						/>
						<div className="p-4">
							<h3 className="text-xl mb-2">{artwork.title}</h3>
							<p className="text-sm text-gray-600">{artwork.year}</p>
						</div>
					</Link>
				))}
			</div>
			<div className="flex justify-center mt-8">
				<button
					onClick={() => paginate(currentPage - 1)}
					disabled={currentPage === 1}
					className="bg-blue-500 text-white border-none px-4 py-2 mx-2 rounded cursor-pointer transition-colors duration-300 hover:bg-blue-600 disabled:bg-gray-400 disabled:cursor-not-allowed"
				>
					Anterior
				</button>
				{getPageNumbers().map((number, index) =>
					number === "..." ? (
						<span key={index} className="mx-2">
							...
						</span>
					) : (
						<button
							key={index}
							onClick={() => paginate(number)}
							className={`px-4 py-2 mx-1 rounded cursor-pointer transition-colors duration-300 ${
								currentPage === number
									? "bg-blue-500 text-white"
									: "bg-white text-blue-500 border border-blue-500 hover:bg-blue-600 hover:text-white"
							}`}
						>
							{number}
						</button>
					)
				)}
				<button
					onClick={() => paginate(currentPage + 1)}
					disabled={currentPage === totalPages}
					className="bg-blue-500 text-white border-none px-4 py-2 mx-2 rounded cursor-pointer transition-colors duration-300 hover:bg-blue-600 disabled:bg-gray-400 disabled:cursor-not-allowed"
				>
					Siguiente
				</button>
			</div>
		</div>
	);
}

export default Home;
