import React, { useState, useEffect, useContext } from "react";
import { supabase } from "../supabaseClient";
import { AuthContext } from "../hooks/useAuth";
import ArtworkForm from "./artworkForm";
import * as XLSX from "xlsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPencilAlt,
	faTrashAlt,
	faCheck,
	faClose,
} from "@fortawesome/free-solid-svg-icons";

function Dashboard() {
	const [artworks, setArtworks] = useState([]);
	const [showForm, setShowForm] = useState(false);
	const [editingArtwork, setEditingArtwork] = useState(null);
	const { user, signOut } = useContext(AuthContext);
	const [sortField, setSortField] = useState("title");
	const [sortDirection, setSortDirection] = useState("asc");
	const [searchTerm, setSearchTerm] = useState("");

	useEffect(() => {
		fetchArtworks();
	}, []);

	async function fetchArtworks() {
		const { data, error } = await supabase.from("artworks").select("*");
		if (error) console.error("Error fetching artworks:", error);
		else setArtworks(data);
	}

	const sortedArtworks = [...artworks]
		.filter((artwork) =>
			artwork.title.toLowerCase().includes(searchTerm.toLowerCase())
		)
		.sort((a, b) => {
			if (sortField === "number") {
				// Parse the field as an integer for proper sorting
				const aVal = parseInt(a[sortField], 10);
				const bVal = parseInt(b[sortField], 10);

				// Handle cases where parsing fails (e.g., non-numeric values)
				if (isNaN(aVal) && isNaN(bVal)) return 0;
				if (isNaN(aVal)) return sortDirection === "asc" ? 1 : -1;
				if (isNaN(bVal)) return sortDirection === "asc" ? -1 : 1;

				return sortDirection === "asc" ? aVal - bVal : bVal - aVal;
			} else {
				// For other fields, use string comparison
				return sortDirection === "asc"
					? (a[sortField] || "").localeCompare(b[sortField] || "")
					: (b[sortField] || "").localeCompare(a[sortField] || "");
			}
		});

	const handleSort = (field) => {
		if (field === sortField) {
			setSortDirection(sortDirection === "asc" ? "desc" : "asc");
		} else {
			setSortField(field);
			setSortDirection("asc");
		}
	};

	async function deleteArtwork(artwork) {
		if (artwork?.picture_url != null) {
			const { error } = await supabase.storage
				.from("artwork-images")
				.remove([artwork?.picture_url]);
			console.log(error);
		}

		if (artwork?.picture_post_url != null) {
			const { error } = await supabase.storage
				.from("artwork-images")
				.remove([artwork?.picture_post_url]);
		}
		const { error } = await supabase
			.from("artworks")
			.delete()
			.eq("id", artwork.id);
		if (error) console.error("Error deleting artwork:", error);
		else fetchArtworks();
	}

	function handleEdit(artwork) {
		setEditingArtwork(artwork);
		setShowForm(true);
	}

	const handleFileUpload = async (e) => {
		const file = e.target.files[0];
		const reader = new FileReader();

		reader.onload = async (e) => {
			const data = new Uint8Array(e.target.result);
			const workbook = XLSX.read(data, { type: "array" });
			const sheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[sheetName];
			const json = XLSX.utils.sheet_to_json(worksheet);

			for (let item of json) {
				if (validateArtworkData(item)) {
					try {
						const { data, error } = await supabase
							.from("artworks")
							.insert(item);
						if (error) throw error;
					} catch (error) {
						console.error("Error inserting artwork:", error);
					}
				}
			}

			alert("File processed and valid artworks uploaded.");
		};

		reader.readAsArrayBuffer(file);
	};

	const validateArtworkData = (item) => {
		const requiredFields = [
			"title",
			"year",
			"measurements",
			"signature",
			"technique",
			"observations",
			"number",
		];
		return requiredFields.every((field) => item.hasOwnProperty(field));
	};

	return (
		<div className="max-w-7xl mx-auto p-8">
			<header className="flex justify-between items-center mb-8">
				<h1 className="text-2xl font-bold">Cheo Perez CMS</h1>
				<button
					onClick={() => signOut()}
					className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
				>
					Cerrar sesión
				</button>
			</header>
			<button
				onClick={() => setShowForm(true)}
				className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mb-4"
			>
				Añadir nueva obra
			</button>
			<div className="mb-4">
				<input
					type="file"
					accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
					onChange={handleFileUpload}
					className="hidden"
					id="file-upload"
				/>
				<label
					htmlFor="file-upload"
					className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 cursor-pointer"
				>
					Subir CSV/Excel
				</label>
			</div>
			<div className="mb-2 mt-12">
				<input
					type="text"
					placeholder="Buscar por nombre"
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					className="px-4 py-2 border border-gray-300 rounded"
				/>
			</div>
			<table className="w-full border-collapse mt-8">
				<thead>
					<tr className="bg-gray-100">
						<th
							className="p-2 text-left cursor-pointer"
							onClick={() => handleSort("title")}
						>
							Título{" "}
							{sortField === "title" && (sortDirection === "asc" ? "▲" : "▼")}
						</th>
						<th
							className="p-2 text-left cursor-pointer"
							onClick={() => handleSort("year")}
						>
							Año{" "}
							{sortField === "year" && (sortDirection === "asc" ? "▲" : "▼")}
						</th>
						<th className="p-2 text-left">Medidas</th>
						<th className="p-2 text-left">Firma</th>
						<th className="p-2 text-left">Técnica</th>
						<th className="p-2 text-left">Observaciones</th>
						<th
							className="p-2 text-left cursor-pointer"
							onClick={() => handleSort("number")}
						>
							Número{" "}
							{sortField === "number" && (sortDirection === "asc" ? "▲" : "▼")}
						</th>
						<th className="p-2 text-left">Imagen</th>
						<th className="p-2 text-left">Imagen trasera</th>
						<th className="p-2 text-left">Acciones</th>
					</tr>
				</thead>
				<tbody>
					{sortedArtworks.map((artwork) => (
						<tr key={artwork.id} className="border-b">
							<td className="p-2">{artwork.title}</td>
							<td className="p-2">{artwork.year}</td>
							<td className="p-2">{artwork.measurements}</td>
							<td className="p-2">{artwork.signature}</td>
							<td className="p-2">{artwork.technique}</td>
							<td className="p-2">{artwork.observations}</td>
							<td className="p-2">{artwork.number}</td>
							<td className="p-2">
								{artwork.picture_url ? (
									<FontAwesomeIcon className="text-green-600" icon={faCheck} />
								) : (
									<FontAwesomeIcon className="text-red-600" icon={faClose} />
								)}
							</td>
							<td className="p-2">
								{artwork.picture_post_url ? (
									<FontAwesomeIcon className="text-green-600" icon={faCheck} />
								) : (
									<FontAwesomeIcon className="text-red-600" icon={faClose} />
								)}
							</td>
							<td className="p-2">
								<button
									onClick={() => handleEdit(artwork)}
									className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 mr-2"
								>
									<FontAwesomeIcon icon={faPencilAlt} />
								</button>
								<button
									onClick={() => deleteArtwork(artwork)}
									className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
								>
									<FontAwesomeIcon icon={faTrashAlt} />
								</button>
							</td>
						</tr>
					))}
				</tbody>
			</table>

			{showForm && (
				<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
					<div className="bg-white rounded-lg shadow-xl max-w-2xl w-full">
						<ArtworkForm
							artwork={editingArtwork}
							onClose={() => {
								setShowForm(false);
								setEditingArtwork(null);
							}}
							onSave={() => {
								fetchArtworks();
								setShowForm(false);
								setEditingArtwork(null);
							}}
						/>
					</div>
				</div>
			)}
		</div>
	);
}

export default Dashboard;
