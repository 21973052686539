import React, { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import imageCompression from "browser-image-compression";

function ArtworkForm({ artwork, onClose, onSave }) {
	const [formData, setFormData] = useState({
		title: "",
		year: "",
		measurements: "",
		signature: "",
		technique: "",
		observations: "",
		number: "",
	});

	const [picture, setPicture] = useState(null);
	const [picturePost, setPicturePost] = useState(null);

	const [previewPicture, setPreviewPicture] = useState("");
	const [previewPicturePost, setPreviewPicturePost] = useState("");

	useEffect(() => {
		if (artwork) {
			setFormData(artwork);
		}
		if (artwork?.picture_url) {
			setPreviewPicture(
				supabase.storage
					.from("artwork-images")
					.getPublicUrl(artwork.picture_url).data.publicUrl
			);
		}
		if (artwork?.picture_post_url) {
			setPreviewPicturePost(
				supabase.storage
					.from("artwork-images")
					.getPublicUrl(artwork.picture_post_url).data.publicUrl
			);
		}
	}, [artwork]);

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleFileChange = async (e) => {
		const file = e.target.files[0];
		if (file) {
			try {
				const options = {
					maxSizeMB: 1,
					maxWidthOrHeight: 1920,
					useWebWorker: true,
				};

				const compressedFile = await imageCompression(file, options);

				if (e.target.name === "picture") {
					setPicture(compressedFile);
					setPreviewPicture(URL.createObjectURL(compressedFile));
				} else if (e.target.name === "picturePost") {
					setPicturePost(compressedFile);
					setPreviewPicturePost(URL.createObjectURL(compressedFile));
				}
			} catch (error) {
				console.error("Error compressing image:", error);
			}
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		let pictureUrl = artwork?.picture_url;
		let picturePostUrl = artwork?.picture_post_url;

		if (picture) {
			const { data, error } = await supabase.storage
				.from("artwork-images")
				.upload(`picture-${formData.title}-${Date.now()}`, picture);
			if (error) console.error("Error uploading picture:", error);
			else pictureUrl = data.path;
		}

		if (picturePost) {
			const { data, error } = await supabase.storage
				.from("artwork-images")
				.upload(`picture-post-${formData.title}-${Date.now()}`, picturePost);
			if (error) console.error("Error uploading picture post:", error);
			else picturePostUrl = data.path;
		}

		const artworkData = {
			...formData,
			picture_url: pictureUrl,
			picture_post_url: picturePostUrl,
		};

		if (artwork) {
			const { error } = await supabase
				.from("artworks")
				.update(artworkData)
				.eq("id", artwork.id);
			if (error) console.error("Error updating artwork:", error);
			else onSave();
		} else {
			const { error } = await supabase.from("artworks").insert(artworkData);
			if (error) console.error("Error creating artwork:", error);
			else onSave();
		}
	};

	const handleDeleteImage = async () => {
		try {
			const imagePath = artwork?.picture_url;
			const { error } = await supabase.storage
				.from("artwork-images")
				.remove([imagePath]);

			if (error) {
				throw error;
			}

			setPreviewPicture(null);
			setPicture(null);

			const artworkData = {
				...formData,
				picture_url: null,
				picture_post_url: artwork?.picture_post_url,
			};

			if (artwork) {
				const { error } = await supabase
					.from("artworks")
					.update(artworkData)
					.eq("id", artwork.id);
				if (error) console.error("Error updating artwork:", error);
				else onSave();
			}
		} catch (error) {
			console.error("Error deleting image:", error.message);
		}
	};

	const handleDeletePostImage = async () => {
		try {
			const imagePath = artwork?.picture_post_url;
			const { error } = await supabase.storage
				.from("artwork-images")
				.remove([imagePath]);

			if (error) {
				throw error;
			}

			setPreviewPicturePost(null);
			setPicturePost(null);

			const artworkData = {
				...formData,
				picture_url: artwork?.picture_url,
				picture_post_url: null,
			};

			if (artwork) {
				const { error } = await supabase
					.from("artworks")
					.update(artworkData)
					.eq("id", artwork.id);
				if (error) console.error("Error updating artwork:", error);
				else onSave();
			}
		} catch (error) {
			console.error("Error deleting image:", error.message);
		}
	};

	return (
		<div className="bg-white p-8 rounded-lg shadow-md max-h-[90vh] overflow-y-auto">
			<h2 className="text-2xl font-bold mb-6">
				{artwork ? "Edit Artwork" : "Add New Artwork"}
			</h2>
			<form onSubmit={handleSubmit} className="space-y-4">
				<input
					className="w-full p-2 border border-gray-300 rounded"
					name="title"
					value={formData.title}
					onChange={handleChange}
					placeholder="Titulo"
				/>
				<input
					className="w-full p-2 border border-gray-300 rounded"
					name="year"
					value={formData.year}
					onChange={handleChange}
					placeholder="Año"
				/>
				<input
					className="w-full p-2 border border-gray-300 rounded"
					name="measurements"
					value={formData.measurements}
					onChange={handleChange}
					placeholder="Medidas"
				/>
				<input
					className="w-full p-2 border border-gray-300 rounded"
					name="signature"
					value={formData.signature}
					onChange={handleChange}
					placeholder="Firma"
				/>
				<input
					className="w-full p-2 border border-gray-300 rounded"
					name="technique"
					value={formData.technique}
					onChange={handleChange}
					placeholder="Técnica"
				/>
				<textarea
					className="w-full p-2 border border-gray-300 rounded"
					name="observations"
					value={formData.observations}
					onChange={handleChange}
					placeholder="Observaciones"
				/>
				<input
					className="w-full p-2 border border-gray-300 rounded"
					name="number"
					value={formData.number}
					onChange={handleChange}
					placeholder="Número"
				/>
				<div className="flex items-start gap-8">
					<p className="w-24">Foto</p>
					<div className="flex flex-col items-start gap-2">
						{previewPicture && (
							<img
								src={previewPicture}
								alt="Preview"
								className="max-w-[100px] max-h-[200px]"
							/>
						)}
						{!previewPicture ? (
							<input
								type="file"
								name="picture"
								onChange={handleFileChange}
								accept="image/*"
								className="w-full"
							/>
						) : (
							<button
								onClick={handleDeleteImage}
								className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
							>
								Eliminar imagen
							</button>
						)}
					</div>
				</div>
				<div className="flex items-start gap-8">
					<p className="w-24">Foto trasera</p>
					<div className="flex flex-col items-start gap-2">
						{previewPicturePost && (
							<img
								src={previewPicturePost}
								alt="Preview"
								className="max-w-[100px] max-h-[200px]"
							/>
						)}
						{!previewPicturePost ? (
							<input
								type="file"
								name="picturePost"
								onChange={handleFileChange}
								accept="image/*"
								className="w-full"
							/>
						) : (
							<button
								onClick={handleDeletePostImage}
								className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
							>
								Eliminar imagen
							</button>
						)}
					</div>
				</div>
				<div className="flex justify-end space-x-4">
					<button
						type="submit"
						className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
					>
						Guardar
					</button>
					<button
						type="button"
						onClick={onClose}
						className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
					>
						Cancelar
					</button>
				</div>
			</form>
		</div>
	);
}

export default ArtworkForm;
