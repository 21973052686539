import React, { useState, useContext } from "react";
import { AuthContext } from "../hooks/useAuth";
import { supabase } from "../supabaseClient";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const LoginContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	background-color: #f0f2f5;
`;

const LoginForm = styled.form`
	background-color: white;
	padding: 2rem;
	border-radius: 8px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
	width: 100%;
	padding: 0.5rem;
	margin-bottom: 1rem;
	border: 1px solid #ccc;
	border-radius: 4px;
`;

const Button = styled.button`
	width: 100%;
	padding: 0.5rem;
	background-color: #4285f4;
	color: white;
	border: none;
	border-radius: 4px;
	cursor: pointer;

	&:hover {
		background-color: #3367d6;
	}
`;

const ForgotPasswordLink = styled.a`
	color: #4285f4;
	text-decoration: none;
	margin-top: 10px;
	cursor: pointer;
`;

function Login() {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");
	const [resetPasswordMode, setResetPasswordMode] = useState(false);
	const { signIn } = useContext(AuthContext);
	const navigate = useNavigate();

	const handleLogin = async (e) => {
		e.preventDefault();
		setError("");
		try {
			const { data, error } = await signIn({ email, password });
			if (error) throw error;
			console.log("Sign in successful:", data);
			navigate("/dashboard");
		} catch (error) {
			setError(error.message);
			console.error("Error details:", error);
		}
	};

	const handleResetPassword = async (e) => {
		e.preventDefault();
		try {
			const { error } = await supabase.auth.resetPasswordForEmail(email, {
				redirectTo: "http://localhost:3000/reset-password",
			});
			if (error) throw error;
			alert("Password reset email sent. Check your inbox.");
		} catch (error) {
			alert(error.message);
		}
	};

	return (
		<LoginContainer>
			<h2>{resetPasswordMode ? "Reset Password" : "Login"}</h2>
			<LoginForm
				onSubmit={resetPasswordMode ? handleResetPassword : handleLogin}
			>
				<Input
					type="email"
					placeholder="Email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					required
				/>
				{!resetPasswordMode && (
					<Input
						type="password"
						placeholder="Password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						required
					/>
				)}
				<Button type="submit">
					{resetPasswordMode ? "Send Reset Email" : "Log In"}
				</Button>
			</LoginForm>
			<ForgotPasswordLink
				onClick={() => setResetPasswordMode(!resetPasswordMode)}
			>
				{resetPasswordMode ? "Back to Login" : "Forgot Password?"}
			</ForgotPasswordLink>
		</LoginContainer>
	);
}

export default Login;
