import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { supabase } from "../supabaseClient";
import { IoMdClose } from "react-icons/io";
import placeholderImage from "../assets/Image-not-found.png";

function ArtworkDetailPage() {
	const [artwork, setArtwork] = useState(null);
	const { id } = useParams();
	const navigate = useNavigate();
	const location = useLocation();

	const handleImageError = (e) => {
		e.target.src = placeholderImage;
	};

	const fetchArtwork = useCallback(async () => {
		const { data, error } = await supabase
			.from("artworks")
			.select("*")
			.eq("id", id)
			.single();
		if (error) {
			console.error("Error fetching artwork:", error);
		} else {
			setArtwork(data);
		}
	}, [id]);

	useEffect(() => {
		fetchArtwork();
	}, [fetchArtwork]);

	const handleClose = () => {
		const params = new URLSearchParams(location.search);
		const page = params.get("page") || "1";
		navigate(`/?page=${page}`);
	};

	if (!artwork) return <div className="text-center py-8">Loading...</div>;

	return (
		<div className="max-w-7xl mx-auto my-8 p-8 bg-gray-100 rounded-xl shadow-md">
			<div className="flex justify-between items-center mb-8">
				<h1 className="text-4xl font-bold text-gray-800">{artwork.title}</h1>
				<button
					onClick={handleClose}
					className="text-3xl text-gray-700 hover:text-gray-900 transition-colors duration-300"
				>
					<IoMdClose />
				</button>
			</div>
			<div className="flex flex-col md:flex-row gap-8">
				<img
					src={
						supabase.storage
							.from("artwork-images")
							.getPublicUrl(artwork.picture_url).data.publicUrl
					}
					alt={artwork.title}
					onError={handleImageError}
					className="w-full md:w-1/3 h-auto rounded-lg shadow-sm"
				/>
				<img
					src={
						supabase.storage
							.from("artwork-images")
							.getPublicUrl(artwork.picture_post_url).data.publicUrl
					}
					alt={artwork.title}
					onError={handleImageError}
					className="w-full md:w-1/3 h-auto rounded-lg shadow-sm"
				/>
				<div className="flex-1">
					<p className="text-lg mb-2">
						<strong className="font-semibold text-gray-700">Año:</strong>{" "}
						{artwork.year}
					</p>
					<p className="text-lg mb-2">
						<strong className="font-semibold text-gray-700">Medidas:</strong>{" "}
						{artwork.measurements}
					</p>
					<p className="text-lg mb-2">
						<strong className="font-semibold text-gray-700">Firma:</strong>{" "}
						{artwork.signature}
					</p>
					<p className="text-lg mb-2">
						<strong className="font-semibold text-gray-700">Técnica:</strong>{" "}
						{artwork.technique}
					</p>
					<p className="text-lg mb-2">
						<strong className="font-semibold text-gray-700">
							Observaciones:
						</strong>{" "}
						{artwork.observations}
					</p>
				</div>
			</div>
		</div>
	);
}

export default ArtworkDetailPage;
