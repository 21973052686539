import React from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
import { AuthProvider } from "./hooks/useAuth";
import Login from "./components/login";
import Dashboard from "./components/dashboard";
import Home from "./components/home";
import ArtworkDetail from "./components/artworkDetail";
import PrivateRoute from "./privateRoute";

function App() {
	return (
		<AuthProvider>
			<Router>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/artwork/:id" element={<ArtworkDetail />} />
					<Route path="/login" element={<Login />} />
					<Route
						path="/dashboard"
						element={
							<PrivateRoute>
								<Dashboard />
							</PrivateRoute>
						}
					/>
				</Routes>
			</Router>
		</AuthProvider>
	);
}

export default App;
