import React, { createContext, useState, useEffect } from "react";
import { supabase } from "../supabaseClient";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const {
			data: { subscription },
		} = supabase.auth.onAuthStateChange((event, session) => {
			if (event === "SIGNED_IN") {
				setUser(session.user);
			} else if (event === "SIGNED_OUT") {
				setUser(null);
			}
			setLoading(false);
		});

		// Get initial session
		supabase.auth.getSession().then(({ data: { session } }) => {
			setUser(session?.user ?? null);
			setLoading(false);
		});

		return () => {
			subscription.unsubscribe();
		};
	}, []);

	const value = {
		signUp: async (data) => {
			const result = await supabase.auth.signUp(data);
			console.log("Sign up result:", result);
			return result;
		},
		signIn: async (data) => {
			console.log("Attempting to sign in with:", data);
			const result = await supabase.auth.signInWithPassword(data);
			console.log("Sign in result:", result);
			return result;
		},
		signOut: () => supabase.auth.signOut(),
		user,
	};

	return (
		<AuthContext.Provider value={value}>
			{!loading && children}
		</AuthContext.Provider>
	);
};
